const analyticsEvent = (category, action, label) => {
    const dataLayer = window.dataLayer ? window.dataLayer : [];

    if (category && action && label) {
        dataLayer.push({
            event: 'eventTracking',
            category: category,
            action: action,
            label: label
        });
    }
};

const attrEvents = () => {
    const trackingElements = [...document.querySelectorAll('[data-event][data-event-category][data-event-label]')];

    if (trackingElements.length > 0) {
        trackingElements.forEach(trackingElem => {
            return trackingElem.addEventListener(trackingElem.getAttribute('data-event'), () => {
                analyticsEvent(
                    trackingElem.getAttribute('data-event-category'),
                    trackingElem.getAttribute('data-event'),
                    trackingElem.getAttribute('data-event-label')
                );
            });
        });
    }
};

export {analyticsEvent, attrEvents};
