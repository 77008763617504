import './app.css';

import './audio/bg.ogg';
import './audio/bg.mp3';

import WebFont from 'webfontloader';
import lazySizes from 'lazysizes';
import {analyticsEvent, attrEvents} from './js/analytics';
import Swiper from 'swiper';
import Navigo from 'navigo';

/* dates */
const currentDate = new Date();
const releaseDates = {
    slavesofgod: new Date(2018, 7, 1),
    meninblack: new Date(2018, 7, 8),
    servantsofthelord: new Date(2018, 7, 10)
};
const dateFormatter = date => {
    return `${date
        .getDate()
        .toString()
        .padStart(2, '0')}. ${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
};

/* 100vh (https://css-tricks.com/the-trick-to-viewport-units-on-mobile/) */
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

/* is production */
const isProd = process.env.NODE_ENV === 'production';

/* service worker */
if ('serviceWorker' in navigator && isProd) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('./sw.js');
    });
}

/* fonts */
WebFont.load({
    custom: {
        families: ['CertaSerif:n4', 'Asbuka:n4', 'Drevnerusskij:n4', 'MyWay:n4'],
        urls: ['./css/fonts.css']
    }
});

/* analytics (EXAMPLE: analyticsEvent('Button', 'click', 'Play');) */
analyticsEvent();
attrEvents();

/* lazy loading (https://github.com/aFarkas/lazysizes) */
lazySizes.init();

/* share buttons with lazyloading */
const shareButtonsContainer = document.querySelector('.share');
if (shareButtonsContainer !== null) {
    shareButtonsContainer.addEventListener('lazyloaded', () => {
        import(/* webpackPrefetch: true */ './js/share').then(func => {
            func.default();
        });
    });
}

/* audio */
{
    const audio = document.querySelector('.audio');
    const audioPromise = audio.play();

    if (audioPromise !== undefined) {
        audioPromise
            .then(() => {
                document.querySelector('.main').setAttribute('data-audio', 'true');
            })
            .catch(() => {
                document.querySelector('.main').removeAttribute('data-audio');
            });
    }

    document.querySelector('.button--audio').addEventListener('click', () => {
        if (audio.paused) {
            audio.play().then(() => {
                document.querySelector('.main').setAttribute('data-audio', 'true');
            });
        } else {
            audio.pause();
            document.querySelector('.main').removeAttribute('data-audio');
        }
    });

    if (!isProd) {
        audio.pause();
    }
}

/* main swiper */
const mainSwiper = new Swiper('.sections.swiper-container', {
    direction: 'vertical',
    speed: 800,
    allowTouchMove: false,
    on: {
        slideChange: function() {
            document.querySelector('.main').setAttribute('data-index', this.realIndex);

            const slideTo = this.realIndex === 1 || this.realIndex === 2 ? 0 : this.realIndex === 3 || this.realIndex === 4 ? 1 : 2;
            document.querySelector('.button--menu').setAttribute('data-slideto', slideTo);

            if (this.realIndex === 1) {
                router.navigate('/slavesofgod');
            } else if (this.realIndex === 3) {
                router.navigate('/meninblack');
            } else if (this.realIndex === 5) {
                router.navigate('/servantsofthelord');
            }

            if (this.realIndex === 7) {
                import(/* webpackPrefetch: true */ './js/epilogue').then(func => {
                    func.default();
                });
            }
        }
    }
});

/* route */
const router = new Navigo(isProd ? 'https://churchslaves.lenta.ru/' : 'http://localhost:8080', false, '#!');

router
    .on({
        servantsofthelord: () => {
            mainSwiper.slideTo(5);
        },
        meninblack: () => {
            mainSwiper.slideTo(3);
        },
        slavesofgod: () => {
            mainSwiper.slideTo(1);
        },
        '*': () => {
            mainSwiper.slideTo(0);
        }
    })
    .resolve();

/* 'continue' button on loader */
document.querySelector('.intro .button--continue').addEventListener('click', () => {
    mainSwiper.slideTo(isProd ? 1 : 5);
});

/* 'back' button on epilogue */
document.querySelector('.epilogue .button--back').addEventListener('click', () => {
    mainSwiper.slideTo(1);
});

/* inner scroll */
const scrollActivated = [];

const innerScroll = id => {
    if (!scrollActivated.includes(id)) {
        scrollActivated.push(id);

        const breakpoints = [...document.querySelectorAll(`.section--${id} .breakpoint`)];
        const contentSwipersProgressBlocksFill = document.querySelector(`.section--${id} .progress-block__fill`);
        const house = document.querySelector(`.section--${id} .house`);
        const buttonNext = document.querySelector(`.section--${id} .button--next`);

        const checkProgress = progress => {
            let windowOpened = false;

            breakpoints.forEach((breakpoint, breakpointIndex, breakpointsArray) => {
                const breakpointPosition = parseInt(breakpoint.dataset.position);

                if (progress * 100 > breakpointPosition) {
                    breakpointsArray.forEach((item, index) => {
                        if (index <= breakpointIndex) {
                            item.classList.add('show');
                        } else {
                            item.classList.remove('show');
                        }
                    });
                } else if (progress * 100 < parseInt(breakpoints[0].dataset.position)) {
                    breakpointsArray.forEach(item => {
                        item.classList.remove('show');
                    });
                }

                if (progress * 100 > breakpointPosition && progress * 100 < breakpointPosition + 4) {
                    house.setAttribute('data-open', '');
                    windowOpened = true;
                    house.setAttribute('data-pic', breakpointIndex + 1);
                } else {
                    if (!windowOpened) {
                        house.removeAttribute('data-open', '');
                    }
                }
            });
        };

        const contentSwiper = new Swiper(`.section--${id} .swiper-container-inner`, {
            direction: 'vertical',
            slidesPerView: 'auto',
            freeMode: true,
            watchSlidesProgress: true,
            mousewheel: true,
            on: {
                progress: progress => {
                    checkProgress(progress);

                    contentSwipersProgressBlocksFill.style.webkitTransform = `scaleY(${progress})`;
                    contentSwipersProgressBlocksFill.style.transform = `scaleY(${progress})`;

                    if (progress > 0.8 && !buttonNext.hasAttribute('data-show')) {
                        buttonNext.setAttribute('data-show', 'true');
                    }

                    /*if (progress > 0.3) {
                        analyticsEvent('Progress', 'scroll', `Progress ${Math.round(progress * 100)} on ${id} section`);
                    }*/
                }
            }
        });

        breakpoints.forEach(breakpoint => {
            breakpoint.addEventListener('click', () => {
                const blockHeight = contentSwiper.virtualSize;
                const percentPosition = breakpoint.dataset.position;
                const scrollPosition = (blockHeight / 100) * percentPosition;
                contentSwiper.setTranslate(-scrollPosition);
            });
        });

        buttonNext.addEventListener('click', () => {
            mainSwiper.slideNext();
        });
    }
};

/* 'continue' button on title slides */
[...document.querySelectorAll('.title .button--continue')].forEach(button => {
    const sectionDate = releaseDates[button.dataset.id];
    if (currentDate < sectionDate) {
        button.setAttribute('disabled', 'disabled');
        button.querySelector('span').textContent = dateFormatter(sectionDate);
    }

    button.addEventListener('click', () => {
        mainSwiper.slideNext();

        innerScroll(button.dataset.id);
    });
});

/* dbclick on titles */
[...document.querySelectorAll('.title .title__title')].forEach(title => {
    title.addEventListener('click', e => {
        if (e.detail === 6 && !isProd) {
            mainSwiper.slideNext();
            innerScroll(title.dataset.id);
        }
    });
});

/* contents menu */
{
    /* main element */
    const main = document.querySelector('.main');

    /* swiper element */
    let menuSwiper = {};

    const closeMenu = () => {
        main.removeAttribute('data-open');

        menuSwiper.destroy();
    };

    /* 'open contents menu' button */
    document.querySelector('.button--menu').addEventListener('click', () => {
        if (main.hasAttribute('data-open')) {
            closeMenu();
        } else {
            main.setAttribute('data-open', 'true');

            menuSwiper = new Swiper('.contents__sections.swiper-container', {
                slidesPerView: 2,
                spaceBetween: 0,
                centeredSlides: true,
                breakpoints: {
                    768: {
                        slidesPerView: 'auto'
                    }
                }
            });

            menuSwiper.slideTo(parseInt(document.querySelector('.button--menu').getAttribute('data-slideto')));
        }
    });

    /* 'continue' button in menu */
    [...main.querySelectorAll('.menu .button--continue')].forEach(button => {
        const sectionDate = releaseDates[button.dataset.id];
        if (currentDate < sectionDate) {
            button.setAttribute('disabled', 'disabled');
            button.querySelector('span').textContent = dateFormatter(sectionDate);
        }

        button.addEventListener('click', () => {
            mainSwiper.slideTo(button.dataset.slideto);

            innerScroll(button.dataset.id);

            closeMenu();
        });
    });
}
